export const title = `INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DEL REG. U.E. 2016/679 E DEL D. LGS. 196/2003`;

export const condition = `Gentile Utente,
<br />
l’accesso al sito www.beacharound.com e la fruizione dei servizi ad esso connessi (prenotazione di hotel, spiagge, ecc.) richiede il trattamento di alcuni dati personali: per questo motivo, come previsto dalla normativa vigente, ti informiamo che:
<br />
<br />
1. TITOLARE DEL TRATTAMENTO
<br />
Il titolare del trattamento dei dati personali è Beacharound s.r.l., con sede legale a Cattolica (RN) in via Renzi n. 7.
<br />
Potrai contattare il titolare del trattamento ai seguenti recapiti: mail info@beacharound.com PEC beacharound.srl@pec.it 
<br />
<br />
2. FINALITA’ E BASE GIURIDICA DEL TRATTAMENTO
<br />
2.1 I tuoi dati personali verranno trattati in ossequio ai principi di liceità e trasparenza, con modalità manuali ed elettroniche, per le seguenti finalità:
<br />
Consentirti di accedere al sito e di usufruire dei servizi ad esso correlati tra i quali, ad esempio, la prenotazione di spiagge ed hotel;
<br />
Consentirci di adempiere agli obblighi di legge a cui siamo tenuti tra i quali, ad esempio, l’obbligo di fatturazione rispetto ai servizi acquistati;
<br />
Consentirci l’eventuale difesa in un qualsiasi giudizio (civile, penale, amministrativo, ecc.), comunque inerente e/o conseguente all’attività esercitata per il tramite del sito www.beacharound.com ;
<br />
Inviarti le nostre newsletter promozionali in relazione a offerte, sconti, pacchetti, ecc. dei nostri partners commerciali (hotel, spiagge, ecc.), a condizione che vi sia il tuo consenso espresso a ricevere tali comunicazioni.
<br />
La base giuridica del trattamento è costituita dall’adempimento del contratto (punto a), dall’obbligo legale di trattare i dati (punto b), dal legittimo interesse del titolare (punto c) e dal consenso dell’interessato (punto d). 
<br />
2.2 Ti informiamo, inoltre, che si svolge trattamento anche dei tuoi dati di navigazione acquisiti dai sistemi informatici e dalle procedure software di questo sito web per il suo normale esercizio. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. Inoltre, potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai datti del sito. Tali dati vengono raccolti sulla base del consenso espresso dall’utente attraverso la persistente navigazione sul sito (comportamento concludente).
<br />
<br />
3. COMUNICAZIONE DEI DATI 
<br />
I dati personali relativi ai trattamenti in questione, per le finalità di cui al punto precedente, potranno essere comunicati:
<br />
A chi eroga direttamente i servizi richiesti (hotel, gestori di spiagge, ecc.), i quali sono autonomi titolari del trattamento dei dati comunicati;
<br />
A coloro i quali, all’interno dell’organizzazione del Titolare, ne abbiano la necessità per effetto della mansione ricoperta: tali soggetti sono le persone autorizzate al trattamento sotto l’Autorità diretta del Titolare ai sensi dell’art. 4 par. 10 Reg. UE 679/16;
<br />
A soggetti terzi a cui il Titolare eventualmente esternalizza talune attività e che di conseguenza erogano allo scrivente determinati servizi strumentali, comunque correlati ai trattamenti e alle finalità sopra descritte, quali ad esempio servizi amministrativi, contabili, fiscali, di revisione, di gestione del sistema informatico e del sito web, di riscossione del credito, di archiviazione di massa. Tali soggetti effettuano trattamenti per conto del Titolare e risultano autorizzati a trattare i dati in qualità di Responsabili del trattamento secondo quanto previsto dall’art. 28 Reg. UE 679/16;
<br />
Alle Autorità amministrative e/o all’Autorità Giudiziaria, qualora ne sia fatta espressa richiesta in forza di provvedimenti cogenti ovvero in caso di difesa del titolare in un qualsiasi giudizio. 
<br />
Qualora se ne ravvisi l’opportunità, il Titolare potrà identificare precisamente tali terzi direttamente all’interessato che ne faccia richiesta. 
<br />
I dati raccolti non saranno oggetto di diffusione e/o di trasferimento verso Paesi Terzi.
<br />
<br />
4. 	CONSERVAZIONE DEI DATI 
<br />
I dati raccolti saranno conservati per il periodo necessario all’erogazione dei servizi richiesti e, successivamente, periodo non superiore a dieci anni, in conformità alle vigenti disposizioni di legge.
<br />
In caso di contenzioso giudiziale o stragiudiziale, nel quale Beacharound s.r.l. sia a qualsiasi titolo coinvolta, i dati potranno essere conservati ed utilizzati per la difesa sino al termine del contenzioso. 
<br />
I dati comunicati al solo fine di ricevere la newsletter commerciale saranno conservati per un periodo non superiore a ventiquattro mesi. 
<br />
<br />
5. DIRITTI DELL’INTERESSATO
<br />
In ogni momento potrai esercitare, ai sensi degli articoli dal 15 al 22 del Regolamento U.E. n. 2016/679, il diritto di:
<br />
Accesso ai tuoi dati personali;
<br />
Ottenere la rettifica e/o la cancellazione dei dati, ad eccezione dei dati che devono essere obbligatoriamente conservati per legge;
<br />
Ottenere la limitazione del trattamento;
<br />
Opporti al trattamento dei tuoi dati personali;
<br />
Revocare il consenso all’invio di newsletter, senza pregiudicare la liceità del trattamento basato sul consenso prima della revoca;
<br />
Proporre Reclamo all’autorità di controllo dello stato in cui risiedi; se residente in Italia, potrai rivolgerti al Garante per la protezione dei dati personali con sede a Roma.
<br />
Puoi esercitare i tuoi diritti con richiesta scritta inviata all’indirizzo e mail info@beacharound.com
<br />
<br />
6. OBBLIGO DI COMUNICARE I DATI
<br />
Non c’è alcun obbligo di comunicare i tuoi dati, ma se vuoi acquistare un servizio offerto dal sito (ad esempio, se vuoi prenotare una spiaggia), dovrai necessariamente registrarti al portale e comunicarci i dati personali richiesti, altrimenti non sarà possibile procedere con la prenotazione. 
<br />
I dati di navigazione vengono acquisiti automaticamente ed utilizzati esclusivamente per le finalità indicate al punto 2.2.`

export const checks = [{
    name: "privacy",
    label: 'Ho preso visione dell’<a href="/privacy" target="_blank">informativa privacy</a>'
}]